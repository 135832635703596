<div class="container">
    <div [ngClass]="{
        'container-item-xlarge': isXLarge(),
        'container-item-large': isLarge(),
        'container-item-medium': isMedium(),
        'container-item-small': isSmall(),
        'container-item-xsmall': isXSmall()
      }">
        <h1>Register today for our introductory offer!</h1>
        <h2>We just opened our doors, and we want to celebrate that with our Chef's Special.</h2>
        <h2>Our Chefs proudly want to give you a first tasting. Join our loyalty platform and receive your first year for free!
        </h2>
        <mat-list>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Sign up and receive our openings promo-code.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Sign in and choose the right plan for your business.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Use the promo-code during checkout.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Enjoy your <s>free 90-day trial</s><span
                        class="primary-title"> free 1 year</span> subscription.</div>
            </mat-list-item>
        </mat-list>
    </div>
</div>