<div class="container">
  <div class="container-content">
    <app-registration-chefs-special></app-registration-chefs-special>
    <div class="container-registration">
      <app-registration-contact-details [maxNameLength]="maxNameLength" [maxEmailLength]="maxEmailLength"
        [registrationDetailsFormGroup]="registrationContactDetailsForm"
        (onRegisterPressed)="onRegister($event)"></app-registration-contact-details>
    </div>
  </div>
  <app-registration-testimonial-banner></app-registration-testimonial-banner>
</div>
<app-bottom-menu></app-bottom-menu>