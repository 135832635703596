<div class="container">
  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Chef's Special</mat-card-title>
        <mat-card-subtitle>Serving All Day Every Day</mat-card-subtitle>
      </mat-card-header>
      <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
        <img src="/assets/img/banners/xlarge/product-banner-chefs-special-opening-xl.webp"
          alt="chefs-special-free-amuse-bouche" />
      </div>
      <mat-card-actions>
        <div *ngIf="!isAuthenticated">
          <button mat-raised-button color="primary" routerLink="/register">
            <mat-icon>app_registration</mat-icon>
            Sign Up
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>

  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h2 class="item-title">Introductory Offer!</h2>
    <h3 class="item-subtitle">We've just opened our doors, and we want to celebrate that with our Chef's Special.</h3>
    <h3 class="item-subtitle">Our Chefs proudly want to give you a first tasting. Join our loyalty platform and receive your first year for free!</h3>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Sign up and receive our openings promo-code.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Sign in and choose the right plan for your business.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Use the promo-code during checkout.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Enjoy your <s>free 90-day trial</s><span class="primary-title">
            free 1 year</span> subscription.</div>
      </mat-list-item>
    </mat-list>
  </div>
</div>